<template >
  <div >
    <h5 v-if="createAfterTableData.length !== 0" >
      {{ $t('key1003729') }}{{ createAfterTableData.length }}{{ $t('key1003730') }} </h5 >
    <Table border :columns="columns" :data="createAfterTableData" ></Table >
  </div >
</template >
<script >
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

export default {
  props: ['data', 'createAfterTableData'],
  data () {
    return {
      columns: [
        {
          type: 'index',
          width: 60,
          align: 'center'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003731'),
          key: 'pickingGoodsNo'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002518'),
          key: 'type'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002469'),
          key: 'warehouseName'
        }
      ]
    };
  },
  created () {},
  computed: {},
  destroyed () {
    this.$parent.$parent.createAfterTableData = [];
  }
};
</script >

<style ></style >
